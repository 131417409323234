<template>
  <div>
    <SecondNavBar />
    <main>
      <div
        class="card shadow col-lg-8 offset-lg-2 mt-4"
        style="padding-top:10px;"
      >
        <div class="row">
          <div class="col-lg-10">
            <h3 id="room-name-title" class="text-purple">
              {{ roomName }} - Status: {{ getRoomStatus }}
            </h3>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col-lg-12">
            <p class="text-danger ml-3">
              NOTE: Select the SAVE button to save room information before
              navigating to another page.
            </p>
          </div>
        </div>
        <div class="row mt-1">
          <div class="col-lg-4 ml-3">
            Client Street Name: <span>{{ client.streetName }}</span>
          </div>

          <div class="col-lg-6 tan-background-div">
            <div class="row">
              <div class="col-lg-8 col-md-7"><h3>Accessibility Rating&trade;:</h3></div>
              <div class="col-lg-4 col-md-4 text-primary">
                <h3>
                  {{
                     accessibilityScore ||
                      accessibilityScore == 0 ||
                      accessibilityScore == "0"
                      ? Math.round(accessibilityScore) + "%"
                      : "----"
                  }}
                </h3>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-8">
                <h3>
                  <span class="text-success" v-if="getIfPreassessmentCompleted"
                    >(Initial:
                    {{ Math.round(room.accessibilityPreScore) }}%)</span
                  >
                </h3>
              </div>
            </div>
            <div class="row mt-1">
              <div class="col-lg-8 col-md-8"><h3>Safety Rating:</h3></div>
              <div class="col-lg-4 col-md-4 text-primary">
                <h3>
                  {{
                    (safetyScore || safetyScore == 0 || safetyScore == "0") && safetyScore !== null
                      ? Math.round(safetyScore * 10) + "%"
                      : "----"
                  }}
                </h3>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-8">
                <h3>
                  <span class="text-success" v-if="getIfPreassessmentCompleted"
                    >(Initial:
                    {{ Math.round(room.personalSafetyPreScore * 10) }}%)</span
                  >
                </h3>
              </div>
            </div>
          </div>
        </div>
        <RoomForm
          :roomName="roomName"
          :roomProp="room"
          :locationDescription="locationDescription"
          :roomGoals="roomGoals"
          :clientId="clientId"
          :preAssessmentNotes = "room ? room.preAssessmentNotes : ''"
          :postAssessmentNotes = "room ? room.postAssessmentNotes : ''"
          :is-custom-prop="isCustom"
          @calculate-accessibility-score="calculateAccessibilityScore"
          @calculate-safety-score="calculateSafetyScore"
          @set-preassessment-accessibility="setPreAccessibility"
          @set-preassessment-safety="setPreAccessibility"
          @set-loading="setLoading"
        />
        <div class="vld-parent">
          <loading :active.sync="isLoading" :can-cancel="false"></loading>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import SecondNavBar from "../../components/Clients/SecondNavBar";
import router from "../../router/index";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
const ROOM_TYPES = [
  "Bathroom",
  "Bedroom",
  "Dining Space",
  "Entryway",
  "Kitchen",
  "Living Room",
  "Hallway",
  "Outdoor Space",
  "Stairs",
  "Laundry Room",
];

export default {
  props: [
    "roomProp",
    "clientProp",
    "roomNameProp",
    "clientIdProp",
    "isCustomProp",
  ],
  data() {
    return {
      clientId: this.clientIdProp,
      roomName: this.roomNameProp
        ? this.roomNameProp
        : localStorage.getItem("CreateRoomFormName"),
      isCustom: !ROOM_TYPES.includes(this.roomName),
      room: this.roomProp,
      client: this.clientProp
        ? this.clientProp
        : JSON.parse(localStorage.getItem("CurrentClient")),
      safetyScore: null,
      accessibilityScore: null,
      locationDescription: "",
      preassessmentSafetyScore: 0,
      preassessmentAccessibilityScore: 0,
      preDisplaySafetyScore: 0,
      preDisplayAccessibilityScore: 0,
      roomGoals: "",
      isLoading: false,
    };
  },
  components: {
    RoomForm: () => import("../../components/Rooms/RoomForm"),
    SecondNavBar,
    Loading,
  },
  mounted() {
    this.isCustom = !ROOM_TYPES.includes(this.roomName);
    this.$nextTick(() => {
      this.isCustom = !ROOM_TYPES.includes(this.roomName);
    });
    document.body.scrollTop = document.documentElement.scrollTop = 0;
    if (this.roomProp == null) {
      if (this.$route.params.roomId) {
        var clientId = this.$route.params.id;
        router.push(`/clients/${clientId}`);
      } else {
        this.client = JSON.parse(localStorage.getItem("CurrentClient"));
      }
    } else {
      this.room = this.roomProp;
      this.calculateAccessibilityScore(this.room.accessibilityPostScore);
    }
  },
  computed: {
    isCustomText() {
      return this.isCustomProp ? true : false;
    },
    getRoomStatus: function() {
      return this.client.status;
    },
    getIfPreassessmentCompleted: function() {
      if (this.room) {
        if (
          this.room.status &&
          (this.room.status == "Follow-Up Assessment Started" ||
            this.room.status == "Follow-Up Assessment Completed")
        ) {
          return true;
        }
      }
      return false;
    },
  },
  methods: {
    setLoading: function(e) {
      if (e) {
        this.isLoading = true;
      } else {
        this.isLoading = false;
      }
    },
    calculateAccessibilityScore: function(e) {
      this.accessibilityScore = Math.round(e);
    },
    calculateSafetyScore: function(e) {
      this.safetyScore = e !== null ? Math.round(e) : null;
    },
    setPreAccessibility: function(e) {
      if (e) this.preassessmentAccessibilityScore = Number(e);
      else this.preassessmentAccessibilityScore = 0;
    },
    setPreSafetyScore: function(e) {
      if (e) this.preassessmentSafetyScore = Number(e);
      else this.preassessmentSafetyScore = 0;
    },
  },
};
</script>

<style>
.text-purple {
  color: #6c207e !important;
}
.tan-background-div {
  background-color: rgb(248, 248, 205);
}
</style>
